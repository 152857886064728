@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-DarkBlue;
    @apply scroll-smooth;
  }
}

html {
  scroll-behavior: smooth !important;
}

/* pagination */
.ant-pagination-item,
.ant-pagination-next,
.ant-pagination-prev {
  border: 1px solid #d8d8d8 !important;
  border-radius: 5px !important;
  height: 20px !important;
  min-width: 37px !important;
}
.ant-pagination-item a,
.ant-pagination-next a,
.ant-pagination-prev a {
  display: block;
  height: 100%;
  color: #515151 !important;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
}
.ant-pagination-item-active {
  background-color: #006097 !important;
  border-color: #006097 !important;
}
.ant-pagination-item-active a {
  color: white !important;
}
.ant-pagination-next:hover,
.ant-pagination-prev:hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.06);
}

/* tab */
.ant-tabs {
  width: 100%;
}

.ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-tab {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 0;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}

.ant-tabs-nav::before {
  margin: 0 34px;
  border-color: rgba(0, 0, 0, 0.5) !important;
  bottom: 2.5px !important;
}

.ant-tabs-ink-bar {
  width: 232px !important;
  height: 5px !important;
  border-radius: 10px;
  background: #006097 !important;
}

/* form */
.ant-form-item {
  margin-bottom: 0px;
}

/* button */
.ant-btn-primary {
  color: #fff;
  border-color: #006097;
  background: #006097;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: rgba(0, 0, 0, 0.45);
}

.ant-btn-primary:hover {
  color: #fff;
  background: #478fb9 !important ;
}

/* input password */
#basic_password,
#basic_confirmPassword {
  background: #d9dbde;
  border-radius: 5px;
  margin-left: -5px;
  padding-left: 5px;
}

/* checkbox */
.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #006097;
  border-color: #006097;
}

/* radio */
.ant-radio-wrapper {
  font-size: 17px !important;
  font-weight: 400;
  color: black;
  display: flex;
  align-items: center;
  gap: 14px;
}

.ant-radio-wrapper span {
  padding: 0;
}

.ant-radio-wrapper .ant-radio .ant-radio-inner {
  width: 26px;
  height: 26px;
  border: 2px solid #3f4d5c !important;
  background-color: white !important;
}

.ant-radio-wrapper .ant-radio-checked::after {
  border: 1px solid #3f4d5c !important;
}

.ant-radio-wrapper .ant-radio .ant-radio-inner::after {
  background-color: #3f4d5c;
  transform: scale(1) !important;
}

/* input search */
.ant-input-wrapper {
  height: 100%;
  border-radius: 10px;
}

.ant-input-wrapper input {
  height: 100%;
  font-size: 17px;
  font-weight: 700;
}
.ant-input-wrapper .ant-input-affix-wrapper {
  height: 100%;
}

.ant-input-wrapper .ant-input-affix-wrapper:hover {
  z-index: 1 !important;
}

.ant-input-group-wrapper .ant-input-wrapper .ant-input-group-addon {
  background-color: white;
  border: 1px solid #d9d9d9 !important;
  border-left: 0px !important;
  padding: 0 6px !important;
}
.ant-input-wrapper .ant-input-group-addon button {
  border: 0;
  padding: 0;
  background-color: white;
}
.ant-input-wrapper .ant-input-group-addon button:hover {
  background: white !important;
}
.ant-input-wrapper .ant-input-group-addon button .ant-btn-icon {
  scale: 2;
}

/* quote */
.quote-form input,
.quote-form .ant-select {
  height: 40px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 700;
  color: #3f4d5c;
  background: white;
}

.quote-form .ant-select-selector {
  border-radius: 10px !important;
  font-size: 14px;
  font-weight: 500;
  color: #8d8d8d;
}

.quote-form .ant-select-selection-placeholder,
#basic_address_line,
.ant-select-selection-item {
  font-size: 16px;
  font-weight: 700 !important;
  color: #3f4d5c;
}

.quote-form #option-contact .ant-radio-wrapper {
  box-sizing: border-box;
  width: 250px;
  height: 40px;
  border: 3px solid #006097;
  border-radius: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px !important;
  font-weight: 700;
  color: #006097;
}

.quote-form #option-contact .ant-radio {
  display: none;
}

.quote-form #option-contact .ant-radio-wrapper-checked {
  color: white;
  background-color: #006097;
}

.input-otp__field {
  max-width: none !important;
}

#contact-form .ant-form-item-required::before {
  content: none;
}

@keyframes animate-zoomin {
  0% {
    opacity: 0.5;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes animate-fadein {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate-moveinleft {
  0% {
    opacity: 0.5;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes animate-moveinright {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.slick-list {
  min-height: 24px;
}

.no-preflight h1 {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}
.no-preflight h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}
.no-preflight h3 {
  font-size: 1.17em;
  font-weight: 700;
  line-height: 1.5;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.no-preflight h4 {
  font-size: 1em;
  font-weight: 700;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}
.no-preflight h5 {
  font-size: 0.83em;
  font-weight: 700;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}
.no-preflight h6 {
  font-size: 0.67em;
  font-weight: 700;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
}

.no-preflight p {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.no-preflight a {
  color: #004cff;
}
.no-preflight:hover a {
  color: #5f84da;
}

.no-preflight ul {
  list-style-type: disc;
  padding-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.no-preflight li {
  margin-bottom: 5px;
}

.no-preflight ol {
  list-style-type: decimal;
  padding-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.no-preflight figcaption {
  z-index: 2;
  padding: 1em 0.5em;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
}

.no-preflight pre {
  background-color: #f9f9f9;
  word-wrap: break-word;
  white-space: pre-wrap !important;
  line-height: 1.45;
  color: #666;
  margin: 0 0 10px 0;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  padding: 8px;
}

.no-preflight blockquote {
  display: block;
  font-family: inherit;
  font-size: inherit;
  color: #999;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0 5px 0 20px;
  border: solid #b1b1b1;
  border-width: 0 0 0 5px;
}

.no-preflight figure {
  width: auto !important;
  height: auto !important;
  position: relative;
}

.no-preflight table {
  width: 100%;
}

.no-preflight td {
  border: #e1e1e1 solid 1px;
}

.no-preflight th {
  border: #e1e1e1 solid 1px;
  border-bottom: #333333 solid 1px;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.no-preflight th > div > span {
  font-size: 16px;
  font-weight: bold;
}

.no-preflight th > div {
  font-size: 16px;
  font-weight: bold;
  font-family: Helvetica Neue;
}


.no-preflight .se-video-container iframe {
  position: absolute;
}
